
import axios from 'axios';

const Token = localStorage.getItem('token');
const Role = localStorage.getItem('role');

const api = 'https://anganwadi.backend.vibhohcm.com/'

const createAxiosInstance = (token) => {
    const instance = axios.create({
        baseURL: `${api}/`,
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
            'x-token': token
        }
    });
    instance.interceptors.response.use(
        (response) => response, // If the response is successful, just return it
        (error) => {
            console.log(error);
 
            if (error.response && error.response.status === 401) {
                // Token expired or not valid
                if (Role === "superAdmin") {
                    localStorage.removeItem('token'); // Clear the token
                    localStorage.removeItem('role'); // Clear the token
                    window.location.href = '/superadmin'; // Redirect to login page
                } else {
                    localStorage.removeItem('token'); // Clear the token
                    localStorage.removeItem('role'); // Clear the token
                    window.location.href = '/admin'; // Redirect to login page
                }
 
            }
            return Promise.reject(error); // Return the error if it's not 401
        }
    );
 
    return instance;

}

export const instance = createAxiosInstance(Token);

export const updateToken = (newToken) => {
    instance.defaults.headers['Authorization'] = `Bearer ${newToken.trim()}`;
    instance.defaults.headers['x-token'] = newToken    
};

export default instance;